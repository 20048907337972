<template> 
  <card
    v-model="item"
    @openAuth="$emit('openAuth')"
    @delete="$emit('delete')"
    @refresh="$emit('refresh')"
    @getExternalLink="$emit('getExternalLink')"
  >
    <template #header>
      <b-row align-v="center" class="d-flex flex-nowrap justify-content-between w-100">
        <svg class="mercado-livre-svg-logo-header ml-1" version="1.1" id="svg2369" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 1000 635.5" style="enable-background:new 0 0 1000 635.5" xml:space="preserve">
          <g id="g366-2" transform="matrix(6.04784 0 0 -6.04784 247.357 449.768)">
            <path id="path368-5" class="st0" d="M110.9 24.7c0 27.4-34 49.6-75.9 49.6S-40.9 52-40.9 24.7v-2.9c0-29 29.7-52.5 75.9-52.5 46.5 0 75.9 23.5 75.9 52.5v2.9z"/>
          </g>
          <g id="g370-9" transform="matrix(6.04784 0 0 -6.04784 242.487 449.726)">
            <path id="path372-8" d="M108.8 24.7C108.8-1 76.1-22 35.8-22s-73 20.9-73 46.7 32.7 46.7 73 46.7 73-20.9 73-46.7" style="fill:#f7d032"/>
          </g>
          <g id="g374-8" transform="matrix(6.04784 0 0 -6.04784 79.536 425.036)">
            <path id="path376-4" class="st2" d="M38.5 35.4c0-.1-.8-.8-.3-1.4 1.2-1.5 4.7-2.3 8.3-1.5 2.1.5 4.9 2.7 7.5 4.8 2.9 2.3 5.7 4.6 8.6 5.5 3 1 
              5 .6 6.3.2 1.4-.4 3.1-1.3 5.7-3.3 5-3.8 25.1-21.3 28.5-24.3 2.8 1.3 15.2 6.6 32.1 10.3-1.5 9-6.9 17.2-15.2 24-11.6-4.9-25.7-7.4-39.5-.6-.1 
              0-7.6 3.6-14.9 3.4-11-.3-15.7-5-20.7-10l-6.4-7.1z"/>
          </g>
          <g id="g378-5" transform="matrix(6.04784 0 0 -6.04784 186.66 462.023)">
            <path id="path380-2" class="st2" d="M84.7 19.4c-.2.2-23.6 20.7-28.9 24.6-3.1 2.3-4.8 2.9-6.6 
              3.1-.9.1-2.2-.1-3.1-.3-2.5-.7-5.7-2.8-8.5-5.1-3-2.4-5.8-4.6-8.3-5.2-3.3-.7-7.4.1-9.2 1.4-.7.5-1.3 1.1-1.5 
              1.7-.7 1.6.6 2.8.8 3.1l6.4 7c.8.8 1.5 1.5 2.3 2.2-2.1-.3-4-.8-5.9-1.3-2.3-.7-4.6-1.3-6.9-1.3-1 0-6 .8-7 1.1-5.8 
              1.6-11 3.2-18.6 6.7-9.2-6.8-15.3-15.4-17.1-24.8 1.3-.4 3.4-1 4.3-1.2 20.8-4.6 27.2-9.4 28.4-10.4 1.3 
              1.4 3.1 2.3 5.1 2.3 2.3 0 4.4-1.2 5.6-2.9 1.2.9 2.8 1.7 4.9 1.7 1 0 2-.2 3-.5 2.3-.8 3.6-2.4 4.2-3.8.8.4 
              1.8.6 2.9.6 1.1 0 2.3-.3 3.4-.8 3.8-1.6 4.4-5.4 4.1-8.2h.8c4.5 0 8.2-3.7 8.2-8.2 0-1.4-.4-2.7-1-3.9 1.2-.7 
              4.4-2.3 7.1-1.9 2.2.3 3 1 3.3 1.5.2.3.4.6.2.9L51.2 4s-1 .9-.6 1.3c.3.4.9-.2 1.3-.5 3-2.5 6.6-6.2 6.6-6.2.1 0 
              .3-.5 1.6-.8 1.2-.2 3.2-.1 4.6 1.1.4.3.7.7 1 1l-.1-.1c1.5 1.9-.2 3.9-.2 3.9l-6.8 7.7s-1 .9-.6 1.3c.3.3.9-.2 1.4-.5C62 
              10.5 65 7.4 68 4.5c.6-.4 3.2-2 6.6.2 2.1 1.4 2.5 3 2.4 4.3-.1 1.7-1.4 2.9-1.4 2.9l-9.3 9.4s-1 .8-.6 1.3c.3.4.9-.2 1.3-.5 3-2.5 
              11-9.9 11-9.9.1-.1 2.9-2.1 6.3.1 1.2.8 2 2 2.1 3.3 0 2.4-1.7 3.8-1.7 3.8"/>
            </g>
            <g id="g382-9" transform="matrix(6.04784 0 0 -6.04784 110.743 481.97)">
              <path id="path384-2" class="st2" d="M52 10.8c-1.4 0-3-.8-3.2-.7-.1.1.1.7.2 1 .1.3 2 6.1-2.6 8.1-3.6 1.5-5.7-.2-6.5-1-.2-.2-.3-.2-.3.1-.1 
                1-.5 3.8-3.6 4.8-4.4 1.3-7.2-1.7-7.9-2.8-.3 2.5-2.4 4.4-5 4.4-2.8 0-5.1-2.3-5.1-5.1s2.3-5.1 5.1-5.1c1.4 0 2.6.5 3.5 1.4v-.2c-.2-1.2-.6-5.8 
                4.2-7.7 1.9-.7 3.6-.2 4.9.8.4.3.5.2.4-.2-.2-1.2 0-3.7 3.6-5.1 2.7-1.1 4.3 0 5.3 1 .5.4.6.3.6-.3C45.7.7 48.6-2 52-2c3.5 0 6.4 2.9 6.4 6.4 0 
                3.6-2.9 6.4-6.4 6.4"/>
              </g>
              <g id="g386-5" transform="matrix(6.04784 0 0 -6.04784 110.743 502.677)">
                <path id="path388-2" class="st0" d="M52 1.9c-3.2 0-5.8 2.5-5.9 5.7 0 .3 0 1-.6 1-.3 0-.5-.2-.7-.4-.7-.7-1.6-1.3-2.9-1.3-.6 0-1.2.1-1.9.4-3.4 
                1.4-3.4 3.7-3.3 4.6 0 .3 0 .5-.1.7l-.2.2H36c-.2 0-.4-.1-.6-.2-1-.7-1.9-1-2.9-1-.5 0-1.1.1-1.6.3-4.5 1.7-4.1 5.9-3.9 7.2 0 .3 0 
                .5-.2.6l-.3.3-.3-.3c-.9-.8-2-1.3-3.2-1.3-2.6 0-4.6 2.1-4.6 4.6 0 2.6 2.1 4.6 4.6 4.6 2.3 0 4.3-1.7 4.6-4l.2-1.2.7 1.1c.1.1 1.9 3 5.4 2.9.7 0 
                1.3-.1 2-.3 2.7-.8 3.2-3.3 3.3-4.4.1-.6.5-.6.6-.6.2 0 .4.2.5.3.5.5 1.6 1.4 3.4 1.4.8 0 1.7-.2 2.6-.6 4.4-1.9 2.4-7.4 2.4-7.5-.4-.9-.4-1.3 
                0-1.6l.2-.1h.1c.2 0 .4.1.8.2.6.2 1.5.5 2.3.5 3.3 0 6-2.7 6-6-.1-3.1-2.8-5.8-6.1-5.8m46.2 26.7C91 34.9 74.4 49.4 69.9 52.7c-2.6 1.9-4.3 3-5.9 
                3.4-.7.2-1.7.4-2.9.4-1.1 0-2.4-.2-3.7-.6-2.9-.9-5.8-3.2-8.6-5.5l-.1-.1c-2.6-2.1-5.3-4.2-7.4-4.7-.9-.2-1.8-.3-2.7-.3-2.3 0-4.3.7-5.1 1.6-.1.2 0 
                .4.3.8v.1l6.3 6.8c5 5 9.6 9.6 20.4 9.9h.5c6.7 0 13.4-3 14.2-3.4 6.3-3.1 12.8-4.6 19.3-4.6 6.8 0 13.8 1.7 21.2 5.1-.8.7-1.7 1.4-2.6 
                2-6.5-2.8-12.7-4.2-18.6-4.2-6.1 0-12.2 1.5-18.1 4.3-.3.2-7.7 3.6-15.4 3.6h-.6c-9.1-.2-14.2-3.4-17.6-6.2-3.3-.1-6.2-.9-8.8-1.6-2.3-.6-4.3-1.2-6.2-1.2-.8 
                0-2.2.1-2.3.1-2.2.1-13.4 2.8-22.2 6.2-.9-.6-1.8-1.3-2.6-2 9.3-3.8 20.6-6.7 24.1-7 1-.1 2-.2 3.1-.2 2.4 0 4.7.7 7 1.3 1.3.4 2.8.8 4.4 
                1.1-.4-.4-.8-.8-1.3-1.2l-6.4-7c-.5-.5-1.6-1.9-.9-3.6.3-.7.9-1.3 1.7-1.9 1.5-1 4.3-1.7 6.8-1.7 1 0 1.9.1 2.7.3 2.7.6 5.5 2.9 8.5 5.2 2.4 1.9 5.8 4.3 8.4 5 
                .7.2 1.6.3 2.3.3h.6c1.7-.2 3.4-.8 6.3-3 5.3-4 28.7-24.4 28.9-24.6 0 0 1.5-1.3 1.4-3.4-.1-1.2-.7-2.3-1.9-3-1-.6-2-1-3.1-1-1.6 0-2.6.7-2.7.8-.1.1-8.1 7.4-11 
                9.9-.5.4-.9.7-1.4.7-.2 0-.5-.1-.6-.3-.5-.6.1-1.4.7-1.9l9.4-9.4s1.2-1.1 1.3-2.5c.1-1.6-.7-2.9-2.2-3.9-1.1-.7-2.2-1.1-3.3-1.1-1.4 0-2.4.7-2.7.8l-1.3 1.3c-2.5 
                2.4-5 4.9-6.8 6.5-.5.4-.9.7-1.4.7-.2 0-.4-.1-.6-.2-.2-.2-.4-.7.2-1.4.2-.3.5-.5.5-.5l6.8-7.7c.1-.1 1.4-1.7.2-3.3H78l-.6-.6c-1.2-1-2.7-1.1-3.3-1.1-.3 
                0-.6 0-.9.1-.7.1-1.1.3-1.3.6l-.1.1c-.4.4-3.8 3.9-6.7 6.3-.4.3-.8.7-1.3.7-.2 
                0-.4-.1-.6-.3-.6-.6.3-1.5.6-1.9l5.8-6.4c0-.1-.1-.2-.2-.4-.2-.3-.9-1-3-1.3h-.8c-2.2 0-4.5 1.1-5.7 1.7.5 1.2.8 2.4.8 3.7 0 
                4.8-3.9 8.7-8.7 8.7h-.3c.2 2.2-.2 6.3-4.4 8.2-1.2.5-2.4.8-3.6.8-.9 0-1.8-.2-2.7-.5-.9 1.7-2.3 3-4.3 3.6-1.1.4-2.1.5-3.1.5-1.8 
                0-3.4-.5-4.9-1.6-1.4 1.7-3.5 2.8-5.7 2.8-1.9 0-3.8-.8-5.2-2.1-1.8 1.4-9 6-28.3 10.3-.9.2-3.1.8-4.4 1.2-.2-1-.4-2.1-.5-3.2 0 0 
                3.5-.9 4.2-1 19.7-4.4 26.2-8.9 27.3-9.8-.4-.9-.6-1.9-.6-2.8 0-4.1 3.3-7.4 7.4-7.4.5 0 .9 0 1.3.1.6-3 2.6-5.3 5.6-6.4.9-.3 
                1.8-.5 2.6-.5.6 0 1.1.1 1.7.2.6-1.4 1.8-3.2 4.6-4.3 1-.4 1.9-.6 2.9-.6.8 0 1.5.1 2.2.4 1.3-3.2 4.5-5.4 8-5.4 2.3 0 4.6.9 6.2 2.6 
                1.4-.8 4.4-2.2 7.4-2.2.4 0 .8 0 1.1.1 3 .4 4.4 1.5 5 2.4.1.2.2.3.3.5.7-.2 1.5-.4 2.4-.4 1.6 0 3.2.6 4.8 1.7 1.5 1.1 2.6 2.7 2.8 
                4.1V8c.6 0 1.2-.1 1.7-.1 1.7 0 3.3.5 4.9 1.5 3 2 3.6 4.6 3.5 6.3.5-.1 1.1-.2 1.6-.2 1.6 0 3.1.5 4.6 1.4 1.9 1.2 3 3 3.2 5.2.1 1.5-.2 2.9-1 
                4.2 5.1 2.2 16.7 6.4 30.3 9.5-.1 1.1-.2 2.1-.4 3.1-16.6-3.6-28.9-8.9-32-10.3"/>
              </g>
              <g id="g390-8-5" transform="matrix(5.9915 0 0 -5.9915 667.618 429.77)">
              </g>
            </svg>
        <h2 class="px-1 d-flex flex-nowrap">
          <span>
            Mercado Livre 
          </span>
          </h2>
        <feather-icon
          :icon="active ? 'CheckIcon' : 'XIcon'"
          :class="active ? 'text-primary' : 'text-danger'" 
          size="30"
        />
      </b-row>
    </template>
      <template #footer>
          <div align="right">
            <b-button @click="emitAdd" :disabled="loading" class="mercado-livre-confirm">
              <span  v-if="!loading">ADD</span>
              <b-spinner small v-else/>
            </b-button>
          </div>
      </template>
  </card>
</template>

<script>
import { BImg, BIcon, BRow, BCol, BButton, BSpinner } from 'bootstrap-vue'
import Card from '@/layouts/components/ThirdPartyApps/Card.vue'
import DefaultFooter from '../DefaultFooter.vue'

  export default {
    components: {
      BImg,
      BIcon,
      BRow,
      BCol,
      Card,
      DefaultFooter,
      BButton,
      BSpinner
    },
    props: {
      value: {
        type: Object,
        default: undefined,
      }
    },
    data() {
      return {
        loading: false
      }
    },
    computed: {
      item: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      active() {
        return this.item.token
      }
    },
    methods: {
      emitAdd() {
        this.loading = true
        this.$emit('openAuth')
      }
    }
  }
</script>

<style lang="scss" scoped>
.mercado-livre-svg-logo-header{
  display: inline;
  height: 3em;
  .st0 {
    fill:#2d3277
  }
  .st2 {
    fill:#fff
  }

}

.mercado-livre-confirm{
  background-color: #f7d032 !important;
  outline: none;
  border: none;
}
</style>
